function indexInList(li) {
    if (li.parentNode === null || !(li.parentNode instanceof HTMLElement))
        throw new Error();
    const ref = li.parentNode.children;
    for (let i = 0; i < ref.length; ++i) {
        if (ref[i] === li) {
            return i;
        }
    }
    return 0;
}
function skipNode(node) {
    if (node instanceof HTMLAnchorElement && node.childNodes.length === 1) {
        const first = node.childNodes[0];
        if (first instanceof HTMLImageElement) {
            return first.src === node.href;
        }
    }
    return false;
}
function hasContent(node) {
    return node.nodeName === 'IMG' || node.firstChild != null;
}
function isCheckbox(node) {
    return node.nodeName === 'INPUT' && node instanceof HTMLInputElement && node.type === 'checkbox';
}
let listIndexOffset = 0;
function nestedListExclusive(li) {
    const first = li.childNodes[0];
    const second = li.childNodes[1];
    if (first && li.childNodes.length < 3) {
        return ((first.nodeName === 'OL' || first.nodeName === 'UL') &&
            (!second || (second.nodeType === Node.TEXT_NODE && !(second.textContent || '').trim())));
    }
    return false;
}
function escapeAttribute(text) {
    return text
        .replace(/&/g, '&amp;')
        .replace(/'/g, '&apos;')
        .replace(/"/g, '&quot;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');
}
const filters = {
    INPUT(el) {
        if (el instanceof HTMLInputElement && el.checked) {
            return '[x] ';
        }
        return '[ ] ';
    },
    CODE(el) {
        const text = el.textContent || '';
        if (el.parentNode && el.parentNode.nodeName === 'PRE') {
            el.textContent = `\`\`\`\n${text.replace(/\n+$/, '')}\n\`\`\`\n\n`;
            return el;
        }
        if (text.indexOf('`') >= 0) {
            return `\`\` ${text} \`\``;
        }
        return `\`${text}\``;
    },
    STRONG(el) {
        return `**${el.textContent || ''}**`;
    },
    EM(el) {
        return `_${el.textContent || ''}_`;
    },
    DEL(el) {
        return `~${el.textContent || ''}~`;
    },
    BLOCKQUOTE(el) {
        const text = (el.textContent || '').trim().replace(/^/gm, '> ');
        const pre = document.createElement('pre');
        pre.textContent = `${text}\n\n`;
        return pre;
    },
    A(el) {
        const text = el.textContent || '';
        const href = el.getAttribute('href');
        if (/^https?:/.test(text) && text === href) {
            return text;
        }
        else {
            if (href) {
                return `[${text}](${href})`;
            }
            else {
                return text;
            }
        }
    },
    IMG(el) {
        const alt = el.getAttribute('alt') || '';
        const src = el.getAttribute('src');
        if (!src)
            throw new Error();
        const widthAttr = el.hasAttribute('width') ? ` width="${escapeAttribute(el.getAttribute('width') || '')}"` : '';
        const heightAttr = el.hasAttribute('height') ? ` height="${escapeAttribute(el.getAttribute('height') || '')}"` : '';
        if (widthAttr || heightAttr) {
            return `<img alt="${escapeAttribute(alt)}"${widthAttr}${heightAttr} src="${escapeAttribute(src)}">`;
        }
        else {
            return `![${alt}](${src})`;
        }
    },
    LI(el) {
        const list = el.parentNode;
        if (!list)
            throw new Error();
        let bullet = '';
        if (!nestedListExclusive(el)) {
            if (list.nodeName === 'OL') {
                if (listIndexOffset > 0 && !list.previousSibling) {
                    const num = indexInList(el) + listIndexOffset + 1;
                    bullet = `${num}\\. `;
                }
                else {
                    bullet = `${indexInList(el) + 1}. `;
                }
            }
            else {
                bullet = '* ';
            }
        }
        const indent = bullet.replace(/\S/g, ' ');
        const text = (el.textContent || '').trim().replace(/^/gm, indent);
        const pre = document.createElement('pre');
        pre.textContent = text.replace(indent, bullet);
        return pre;
    },
    OL(el) {
        const li = document.createElement('li');
        li.appendChild(document.createElement('br'));
        el.append(li);
        return el;
    },
    H1(el) {
        const level = parseInt(el.nodeName.slice(1));
        el.prepend(`${Array(level + 1).join('#')} `);
        return el;
    },
    UL(el) {
        return el;
    }
};
filters.UL = filters.OL;
for (let level = 2; level <= 6; ++level) {
    filters[`H${level}`] = filters.H1;
}
function insertMarkdownSyntax(root) {
    const nodeIterator = document.createNodeIterator(root, NodeFilter.SHOW_ELEMENT, function (node) {
        if (node.nodeName in filters && !skipNode(node) && (hasContent(node) || isCheckbox(node))) {
            return NodeFilter.FILTER_ACCEPT;
        }
        return NodeFilter.FILTER_SKIP;
    });
    const results = [];
    let node = nodeIterator.nextNode();
    while (node) {
        if (node instanceof HTMLElement) {
            results.push(node);
        }
        node = nodeIterator.nextNode();
    }
    results.reverse();
    for (const el of results) {
        el.replaceWith(filters[el.nodeName](el));
    }
}
function extractFragment(range, selector) {
    const startNode = range.startContainer;
    if (!startNode || !startNode.parentNode || !(startNode.parentNode instanceof HTMLElement)) {
        throw new Error('the range must start within an HTMLElement');
    }
    const parent = startNode.parentNode;
    let fragment = range.cloneContents();
    if (selector) {
        const contentElement = fragment.querySelector(selector);
        if (contentElement) {
            fragment = document.createDocumentFragment();
            fragment.appendChild(contentElement);
        }
    }
    listIndexOffset = 0;
    const li = parent.closest('li');
    const codeBlock = parent.closest('pre');
    if (codeBlock) {
        const pre = document.createElement('pre');
        pre.appendChild(fragment);
        fragment = document.createDocumentFragment();
        fragment.appendChild(pre);
    }
    else if (li && li.parentNode) {
        if (li.parentNode.nodeName === 'OL') {
            listIndexOffset = indexInList(li);
        }
        if (!fragment.querySelector('li')) {
            const item = document.createElement('li');
            if (!li.parentNode)
                throw new Error();
            const list = document.createElement(li.parentNode.nodeName);
            item.appendChild(fragment);
            list.appendChild(item);
            fragment = document.createDocumentFragment();
            fragment.appendChild(list);
        }
    }
    return fragment;
}

const containers = new WeakMap();
let installed = 0;
const edgeBrowser = /\bEdge\//.test(navigator.userAgent);
function subscribe(container, options) {
    install(container, options);
    return {
        unsubscribe: () => {
            uninstall(container);
        }
    };
}
function install(container, options) {
    const firstInstall = installed === 0;
    installed += containers.has(container) ? 0 : 1;
    const config = Object.assign({
        quoteMarkdown: false,
        copyMarkdown: false,
        scopeSelector: ''
    }, options);
    containers.set(container, config);
    if (firstInstall) {
        document.addEventListener('keydown', quoteSelection);
    }
    if (config.copyMarkdown) {
        container.addEventListener('copy', onCopy);
    }
}
function uninstall(container) {
    const config = containers.get(container);
    if (config == null)
        return;
    containers.delete(container);
    installed -= 1;
    if (installed === 0) {
        document.removeEventListener('keydown', quoteSelection);
    }
    if (config.copyMarkdown) {
        container.removeEventListener('copy', onCopy);
    }
}
function onCopy(event) {
    const target = event.target;
    if (!(target instanceof HTMLElement))
        return;
    if (isFormField(target))
        return;
    const transfer = event.clipboardData;
    if (!transfer)
        return;
    const selection = window.getSelection();
    if (!selection)
        return;
    let range;
    try {
        range = selection.getRangeAt(0);
    }
    catch (_a) {
        return;
    }
    const text = selection.toString();
    const quoted = extractQuote(text, range, true);
    if (!quoted)
        return;
    transfer.setData('text/plain', text);
    transfer.setData('text/x-gfm', quoted.selectionText);
    event.preventDefault();
    selection.removeAllRanges();
    selection.addRange(range);
}
function eventIsNotRelevant(event) {
    return (event.defaultPrevented ||
        event.key !== 'r' ||
        event.metaKey ||
        event.altKey ||
        event.shiftKey ||
        event.ctrlKey ||
        (event.target instanceof HTMLElement && isFormField(event.target)));
}
function findContainer(el) {
    let parent = el;
    while ((parent = parent.parentElement)) {
        if (containers.has(parent)) {
            return parent;
        }
    }
}
function findTextarea(container) {
    for (const field of container.querySelectorAll('textarea')) {
        if (field instanceof HTMLTextAreaElement && visible(field)) {
            return field;
        }
    }
}
function quoteSelection(event) {
    if (eventIsNotRelevant(event))
        return;
    const selection = window.getSelection();
    if (!selection)
        return;
    let range;
    try {
        range = selection.getRangeAt(0);
    }
    catch (_a) {
        return;
    }
    if (quote(selection.toString(), range)) {
        event.preventDefault();
    }
}
function quote(text, range) {
    const quoted = extractQuote(text, range, false);
    if (!quoted)
        return false;
    const { container, selectionText } = quoted;
    const dispatched = container.dispatchEvent(new CustomEvent('quote-selection', {
        bubbles: true,
        cancelable: true,
        detail: { range, selectionText }
    }));
    if (!dispatched) {
        return true;
    }
    const field = findTextarea(container);
    if (!field)
        return false;
    insertQuote(selectionText, field);
    return true;
}
function extractQuote(text, range, unwrap) {
    let selectionText = text.trim();
    if (!selectionText)
        return;
    let focusNode = range.startContainer;
    if (!focusNode)
        return;
    if (focusNode.nodeType !== Node.ELEMENT_NODE)
        focusNode = focusNode.parentNode;
    if (!(focusNode instanceof Element))
        return;
    const container = findContainer(focusNode);
    if (!container)
        return;
    const options = containers.get(container);
    if (!options)
        return;
    if (options.quoteMarkdown && !edgeBrowser) {
        try {
            const fragment = extractFragment(range, options.scopeSelector);
            container.dispatchEvent(new CustomEvent('quote-selection-markdown', {
                bubbles: true,
                cancelable: false,
                detail: { fragment, range, unwrap }
            }));
            insertMarkdownSyntax(fragment);
            selectionText = selectFragment(fragment).replace(/^\n+/, '').replace(/\s+$/, '');
        }
        catch (error) {
            setTimeout(() => {
                throw error;
            });
        }
    }
    return { selectionText, container };
}
function insertQuote(selectionText, field) {
    let quotedText = `> ${selectionText.replace(/\n/g, '\n> ')}\n\n`;
    if (field.value) {
        quotedText = `${field.value}\n\n${quotedText}`;
    }
    field.value = quotedText;
    field.dispatchEvent(new CustomEvent('change', {
        bubbles: true,
        cancelable: false
    }));
    field.focus();
    field.selectionStart = field.value.length;
    field.scrollTop = field.scrollHeight;
}
function visible(el) {
    return !(el.offsetWidth <= 0 && el.offsetHeight <= 0);
}
function selectFragment(fragment) {
    const body = document.body;
    if (!body)
        return '';
    const div = document.createElement('div');
    div.appendChild(fragment);
    div.style.cssText = 'position:absolute;left:-9999px;';
    body.appendChild(div);
    let selectionText = '';
    try {
        const selection = window.getSelection();
        if (selection) {
            const range = document.createRange();
            range.selectNodeContents(div);
            selection.removeAllRanges();
            selection.addRange(range);
            selectionText = selection.toString();
            selection.removeAllRanges();
            range.detach();
        }
    }
    finally {
        body.removeChild(div);
    }
    return selectionText;
}
function isFormField(element) {
    const name = element.nodeName.toLowerCase();
    const type = (element.getAttribute('type') || '').toLowerCase();
    return (name === 'select' ||
        name === 'textarea' ||
        (name === 'input' && type !== 'submit' && type !== 'reset') ||
        element.isContentEditable);
}

export { findContainer, findTextarea, install, quote, subscribe, uninstall };
