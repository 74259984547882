function insertText(textarea, text) {
    const beginning = textarea.value.substring(0, textarea.selectionStart || 0);
    const remaining = textarea.value.substring(textarea.selectionEnd || 0);
    const newline = beginning.length === 0 || beginning.match(/\n$/) ? '' : '\n';
    const textBeforeCursor = beginning + newline + text;
    textarea.value = textBeforeCursor + remaining;
    textarea.selectionStart = textBeforeCursor.length;
    textarea.selectionEnd = textarea.selectionStart;
    textarea.dispatchEvent(new CustomEvent('change', {
        bubbles: true,
        cancelable: false
    }));
    textarea.focus();
}

function install$2(el) {
    el.addEventListener('dragover', onDragover$1);
    el.addEventListener('drop', onDrop$1);
    el.addEventListener('paste', onPaste$2);
}
function uninstall$2(el) {
    el.removeEventListener('dragover', onDragover$1);
    el.removeEventListener('drop', onDrop$1);
    el.removeEventListener('paste', onPaste$2);
}
function onDrop$1(event) {
    const transfer = event.dataTransfer;
    if (!transfer)
        return;
    if (hasFile$1(transfer))
        return;
    if (!hasLink(transfer))
        return;
    const links = extractLinks(transfer);
    if (!links.some(isImageLink))
        return;
    event.stopPropagation();
    event.preventDefault();
    const field = event.currentTarget;
    if (!(field instanceof HTMLTextAreaElement))
        return;
    insertText(field, links.map(linkify).join(''));
}
function onDragover$1(event) {
    const transfer = event.dataTransfer;
    if (transfer)
        transfer.dropEffect = 'link';
}
function onPaste$2(event) {
    const transfer = event.clipboardData;
    if (!transfer || !hasLink(transfer))
        return;
    const links = extractLinks(transfer);
    if (!links.some(isImageLink))
        return;
    event.stopPropagation();
    event.preventDefault();
    const field = event.currentTarget;
    if (!(field instanceof HTMLTextAreaElement))
        return;
    insertText(field, links.map(linkify).join(''));
}
function linkify(link) {
    return isImageLink(link) ? `\n![](${link})\n` : link;
}
function hasFile$1(transfer) {
    return Array.from(transfer.types).indexOf('Files') >= 0;
}
function hasLink(transfer) {
    return Array.from(transfer.types).indexOf('text/uri-list') >= 0;
}
function extractLinks(transfer) {
    return (transfer.getData('text/uri-list') || '').split('\r\n');
}
const IMAGE_RE = /\.(gif|png|jpe?g)$/i;
function isImageLink(url) {
    return IMAGE_RE.test(url);
}

function install$1(el) {
    el.addEventListener('dragover', onDragover);
    el.addEventListener('drop', onDrop);
    el.addEventListener('paste', onPaste$1);
}
function uninstall$1(el) {
    el.removeEventListener('dragover', onDragover);
    el.removeEventListener('drop', onDrop);
    el.removeEventListener('paste', onPaste$1);
}
function onDrop(event) {
    const transfer = event.dataTransfer;
    if (!transfer)
        return;
    if (hasFile(transfer))
        return;
    const textToPaste = generateText(transfer);
    if (!textToPaste)
        return;
    event.stopPropagation();
    event.preventDefault();
    const field = event.currentTarget;
    if (field instanceof HTMLTextAreaElement) {
        insertText(field, textToPaste);
    }
}
function onDragover(event) {
    const transfer = event.dataTransfer;
    if (transfer)
        transfer.dropEffect = 'copy';
}
function onPaste$1(event) {
    if (!event.clipboardData)
        return;
    const textToPaste = generateText(event.clipboardData);
    if (!textToPaste)
        return;
    event.stopPropagation();
    event.preventDefault();
    const field = event.currentTarget;
    if (field instanceof HTMLTextAreaElement) {
        insertText(field, textToPaste);
    }
}
function hasFile(transfer) {
    return Array.from(transfer.types).indexOf('Files') >= 0;
}
function columnText(column) {
    const noBreakSpace = '\u00A0';
    const text = (column.textContent || '').trim().replace(/\|/g, '\\|').replace(/\n/g, ' ');
    return text || noBreakSpace;
}
function tableHeaders(row) {
    return Array.from(row.querySelectorAll('td, th')).map(columnText);
}
function tableMarkdown(node) {
    const rows = Array.from(node.querySelectorAll('tr'));
    const firstRow = rows.shift();
    if (!firstRow)
        return '';
    const headers = tableHeaders(firstRow);
    const spacers = headers.map(() => '--');
    const header = `${headers.join(' | ')}\n${spacers.join(' | ')}\n`;
    const body = rows
        .map(row => {
        return Array.from(row.querySelectorAll('td')).map(columnText).join(' | ');
    })
        .join('\n');
    return `\n${header}${body}\n\n`;
}
function generateText(transfer) {
    if (Array.from(transfer.types).indexOf('text/html') === -1)
        return;
    const html = transfer.getData('text/html');
    if (!/<table/i.test(html))
        return;
    const el = document.createElement('div');
    el.innerHTML = html;
    let table = el.querySelector('table');
    table = !table || table.closest('[data-paste-markdown-skip]') ? null : table;
    if (!table)
        return;
    const formattedTable = tableMarkdown(table);
    return html.replace(/<meta.*?>/, '').replace(/<table[.\S\s]*<\/table>/, `\n${formattedTable}`);
}

function install(el) {
    el.addEventListener('paste', onPaste);
}
function uninstall(el) {
    el.removeEventListener('paste', onPaste);
}
function onPaste(event) {
    const transfer = event.clipboardData;
    if (!transfer || !hasMarkdown(transfer))
        return;
    const field = event.currentTarget;
    if (!(field instanceof HTMLTextAreaElement))
        return;
    const text = transfer.getData('text/x-gfm');
    if (!text)
        return;
    event.stopPropagation();
    event.preventDefault();
    insertText(field, text);
}
function hasMarkdown(transfer) {
    return Array.from(transfer.types).indexOf('text/x-gfm') >= 0;
}

function subscribe(el) {
    install$1(el);
    install$2(el);
    install(el);
    return {
        unsubscribe: () => {
            uninstall$1(el);
            uninstall$2(el);
            uninstall(el);
        }
    };
}

export default subscribe;
